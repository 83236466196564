import { hFov } from "lib/coordinatesHelper";
import { MathUtils } from "three";
const { degToRad } = MathUtils;

export function getCamDistToFitMap(
  camFov: number,
  camAspect: number,
  camAngle: number,
  width: number,
  height: number) {
  
  const hVFov = degToRad(hFov(camFov, camAspect) / 2);
  const hHFov = degToRad(camFov / 2);
  const hWidth = width / 2;
  const hHeight = height / 2;

  // Fit the width
  let d1 = hWidth / Math.tan(hVFov);
  d1 += Math.cos(degToRad(90 - camAngle)) * hHeight; // correct for cam angle

  // fit the height
  let d2 = (Math.sin(degToRad(90 - camAngle)) * hHeight) / Math.tan(hHFov);
  d2 += hHeight * Math.cos(degToRad(90 - camAngle)); // correct for cam angle

  return Math.max(d1, d2);
}
