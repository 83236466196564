import { Vector3, Spherical, MathUtils } from "three";
import {
  sphericalAdd,
  sphericalClamp,
  sphericalLerp,
  sphericalMultiplyScalar
} from "lib/sphericalHelpers";
import { getCamDistToFitMap } from "./getCamDistToFitMap";
const { lerp } = MathUtils;

export class SmoothSphericalCamera {
  camera: THREE.PerspectiveCamera;
  smoothing = 0.2;

  position = new Spherical();
  destination = new Spherical();

  posMin = new Spherical();
  posMax = new Spherical();

  target = new Vector3();
  targetDestination = new Vector3();

  targetMin = new Vector3();
  targetMax = new Vector3();

  filmOffset = 0;
  filmOffsetDestination = 0;

  constructor(camera: THREE.PerspectiveCamera, smoothing: number = 0.2) {
    this.camera = camera;
    this.position.setFromVector3(this.camera.position);
    this.destination.setFromVector3(this.camera.position);
    this.smoothing = smoothing;
  }

  updateCamera(deltaTime: number) {
    this.destination = sphericalClamp(
      this.destination,
      this.posMin,
      this.posMax
    );
    this.position = sphericalLerp(
      this.position,
      this.destination,
      this.smoothing
    );
    this.camera.position.setFromSpherical(this.position);

    // move the center of our 'sphere' and look at it
    this.target = this.target.lerp(this.targetDestination, this.smoothing);
    this.camera.position.add(this.target);
    this.camera.lookAt(this.target);

    // update film offset
    this.camera.filmOffset = lerp(
      this.camera.filmOffset,
      this.filmOffsetDestination,
      this.smoothing
    );
    this.camera.updateProjectionMatrix();
  }

  fitRect(aspect, rectWidth, rectHeight) {
    const distance = getCamDistToFitMap(
      this.camera.fov,
      aspect,
      this.position.phi,
      rectWidth,
      rectHeight
    );
    this.destination.radius = distance + 4;
  }
}
