import { useState, useEffect, useRef } from "react";
import { start } from "repl";

interface PinchEvent extends PointerEvent {
  distance: number;
  deltaDistance: number;
}

type PinchCallbacks = {
  onPointerDown?: (e: PinchEvent) => void;
  onPointerUp?: (e: PinchEvent) => void;
  onPointerMove?: (e: PinchEvent) => void;
  onPinch?: (e: PinchEvent) => void;
};

const pointers = new Map<number, PointerEvent>();

export const usePinch = (element, deps = [], { onPinch }: PinchCallbacks) => {
  const startDistance = useRef(null);

  const handlePointerDown = (e: PinchEvent) => {
    pointers.set(e.pointerId, e);
  };

  const handlePointerUp = (e: PinchEvent) => {
    pointers.delete(e.pointerId);
    if (pointers.size === 0) {
      startDistance.current = null;
    }
  };

  const handlePointerMove = (e: PinchEvent) => {
    pointers.set(e.pointerId, e);
    if (pointers.size !== 2) return;
    // if (!e.isPrimary) return;
    const pointerArray = Array.from(pointers.values());
    const a = pointerArray[0];
    const b = pointerArray[1];

    const dx = a.clientX - b.clientX;
    const dy = a.clientY - b.clientY;
    e.distance = Math.sqrt(dx * dx + dy * dy);
    if (!startDistance.current) startDistance.current = e.distance;
    e.deltaDistance = e.distance - startDistance.current;
    onPinch(e);
    startDistance.current = e.distance;
  };

  const handleWheel = (e: WheelEvent) => {
    // console.log(e);
    // return;
    if (e.deltaMode !== WheelEvent.DOM_DELTA_PIXEL) return;
    const pinchEvent = new PointerEvent("wheel") as PinchEvent;
    pinchEvent.deltaDistance = -e.deltaY;
    onPinch(pinchEvent);
  };

  useEffect(() => {
    element.addEventListener("pointerdown", handlePointerDown);
    element.addEventListener("pointerup", handlePointerUp);
    element.addEventListener("pointermove", handlePointerMove);
    element.addEventListener("wheel", handleWheel);
    return () => {
      element.removeEventListener("pointerdown", handlePointerDown);
      element.removeEventListener("pointerup", handlePointerUp);
      element.removeEventListener("pointermove", handlePointerMove);
      element.removeEventListener("wheel", handleWheel);
    };
  }, [...deps]);

  // return { isDragging: isPinching };
};
