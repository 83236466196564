import { useRef } from "react";

interface Props {
  width: number;
  height: number;
  squareSize: number;
  onPointerOver?: (coord: { x: number; y: number }) => void;
  onPointerDown?: (coord: { x: number; y: number }) => void;
  onPointerLeave?: () => void;
  onPointerClick?: (coord: { x: number; y: number }) => void;
  visible?: boolean;
  positionY?: number;
}

const HoverPlaneCoords = ({
  width,
  height,
  squareSize = 1,
  onPointerOver,
  onPointerDown,
  onPointerLeave,
  onPointerClick,
  visible = false,
  positionY = 0,
}: Props) => {
  const startClickTime = useRef(0);
  const handlePointerMove = (e) => {
    const coords = {
      x: Math.floor(e.point.x / squareSize),
      y: Math.floor(-e.point.z / squareSize),
    };
    onPointerOver && onPointerOver(coords);
  };
  const handlePointerDown = (e) => {
    const coords = {
      x: Math.floor(e.point.x / squareSize),
      y: Math.floor(-e.point.z / squareSize),
    };
    onPointerDown && onPointerDown(coords);
    startClickTime.current = Date.now();
  };
  const handlePointerLeave = (e) => {
    onPointerLeave && onPointerLeave();
  };
  const handlePointerUp = (e) => {
    if (Date.now() - startClickTime.current < 300) {
      const coords = {
        x: Math.floor(e.point.x / squareSize),
        y: Math.floor(-e.point.z / squareSize),
      };
      onPointerClick && onPointerClick(coords);
    }
  };
  return (
    <group
      scale={[width * squareSize, 1, -height * squareSize]}
      onPointerMove={handlePointerMove}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerLeave={handlePointerLeave}
    >
      <mesh
        visible={visible}
        rotation-x={-Math.PI / 2}
        position={[0.5, positionY, 0.5]}
      >
        <planeBufferGeometry args={[1, 1, 1]} />
        <meshBasicMaterial color={"green"} />
      </mesh>
    </group>
  );
};

export default HoverPlaneCoords;
