import { useRef, useLayoutEffect, useState } from "react";
import { GardenSettings, useGardenStore } from "stores/gardenStore";
import { useStateStore } from "stores/stateStore";
import { Mesh, Object3D, Vector3 } from "three";
import HoverPlaneCoords from "./HoverPlaneCoords";
import EditGardenGrid from "./EditGardenGrid";
import Dimension from "../Dimension/Dimension";
import { useTexture } from "@react-three/drei";
import settings from "settings";
const { squareSize } = settings;

export function EditGarden() {
  const toggleRemoveNode = useGardenStore((state) => state.toggleRemoveNode);
  const gardenSettings: GardenSettings = useGardenStore(
    (state) => state.settings
  );
  const getNodeIdAtCoord = useGardenStore((state) => state.getNodeIdAtCoord); 
  const { width, height } = gardenSettings;
  const setCameraTarget = useStateStore((state) => state.setCameraTarget);
  const refHoverObject = useRef<Mesh>(null);
  const refCameraTarget = useRef<Object3D>(null);
  const hoverTexture = useTexture('/textures/selection.png');

  useLayoutEffect(() => {
    setCameraTarget(refCameraTarget.current);
  }, []);

  const pointerHoverHandler = ({ x, y }) => {
    if (!refHoverObject.current) return;
    refHoverObject.current.visible = true;
    refHoverObject.current.position.setX((x + 0.5) * squareSize);
    refHoverObject.current.position.setZ(-(y + 0.5) * squareSize);
    const nodeId = getNodeIdAtCoord({x, y});
    if (gardenSettings.remove.includes(nodeId)) {
      document.body.style.cursor = "url('/pointers/001-add.png'), auto";
    } else {
      document.body.style.cursor = "url('/pointers/019-remove.png'), auto";
    }
  };

  const pointerClickHandler = ({ x, y }) => {
    const nodeId = getNodeIdAtCoord({ x, y });
    toggleRemoveNode(nodeId);
  };

  const pointerLeaveHandler = () => {
    if (!refHoverObject.current) return;
    refHoverObject.current.visible = false;
    refHoverObject.current.position.setX(0.5 * squareSize);
    refHoverObject.current.position.setZ(-0.5 * squareSize);
    document.body.style.cursor = "auto";
  };

  return (
    <>
      <Dimension
        size={width * squareSize}
        position={[0, 0, 0.5]}
        labelStyle={{ top: "1em" }}
      />
      <Dimension
        size={height * squareSize}
        position={[-0.5, 0, -(height * squareSize)]}
        rotation-y={-Math.PI / 2}
        labelStyle={{ left: "-2em" }}
      />
      <group ref={refCameraTarget}>
        <mesh
          ref={refHoverObject}
          visible={false}
          rotation-x={-Math.PI / 2}
          position-y={0.001}
        >
          <planeBufferGeometry args={[squareSize, squareSize]} />
          <meshBasicMaterial map={hoverTexture} transparent />
        </mesh>
        <HoverPlaneCoords
          visible={false}
          width={width}
          height={height}
          squareSize={squareSize}
          onPointerOver={pointerHoverHandler}
          onPointerClick={pointerClickHandler}
          onPointerLeave={pointerLeaveHandler}
        />
        <EditGardenGrid gardenSettings={gardenSettings} />
      </group>
    </>
  );
}
