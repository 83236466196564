import { useState, useEffect, useRef } from "react";

interface DragEvent extends PointerEvent {
  startDeltaX: number;
  startDeltaY: number;
  deltaX: number;
  deltaY: number;
}

type DragCallbacks = {
  onPointerDown?: (e: DragEvent) => void;
  onPointerUp?: (e: DragEvent) => void;
  onPointerMove?: (e: DragEvent) => void;
  onDrag?: (e: DragEvent) => void;
};


export const useDrag = (element, deps = [], {onPointerDown, onPointerUp, onPointerMove, onDrag}: DragCallbacks) => {

  // const [isDragging, setIsDragging] = useState(false);
  const startCoords = useRef({ x: 0, y: 0 });
  const lastCoords = useRef({ x: 0, y: 0 });
  const isDragging = useRef(false);

  const handlePointerDown = (e: DragEvent) => {
    if (!e.isPrimary) return;
    isDragging.current = true;
    startCoords.current = {x: e.clientX, y: e.clientY};
    lastCoords.current = { x: e.clientX, y: e.clientY };

    if (onPointerDown) onPointerDown(e);
  };

  const handlePointerUp = (e: DragEvent) => {
    if (!e.isPrimary) return;
    isDragging.current = false;
    lastCoords.current = { x: e.clientX, y: e.clientY };
    if (onPointerUp) onPointerUp(e);
  };

  const handlePointerMove = (e: DragEvent) => {
    if (!e.isPrimary) return;
    if (onPointerMove) onPointerMove(e);
    if (isDragging.current) {
      e.startDeltaX = e.clientX - startCoords.current.x;
      e.startDeltaY = e.clientY - startCoords.current.y;
      e.deltaX = e.clientX - lastCoords.current.x;
      e.deltaY = e.clientY - lastCoords.current.y;
      if (onDrag) onDrag(e);
      lastCoords.current = { x: e.clientX, y: e.clientY };
    }
  };

  useEffect(() => {
    element.addEventListener("pointerdown", handlePointerDown);
    element.addEventListener("pointerup", handlePointerUp);
    element.addEventListener("pointermove", handlePointerMove);
    return () => {
      element.removeEventListener("pointerdown", handlePointerDown);
      element.removeEventListener("pointerup", handlePointerUp);
      element.removeEventListener("pointermove", handlePointerMove);
    };
  }, [...deps]);

  return { isDragging };
};
