import { useRef, useEffect, useCallback } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";
// import { useGesture } from "@use-gesture/react";
import { gardenViews, UIViews, useStateStore } from "stores/stateStore";
import { useGardenStore } from "stores/gardenStore";
import { useWindowSize } from "@react-hook/window-size";
import { breakPoints } from "styles/styles";
import { SmoothSphericalCamera } from "./SmoothSphericalCamera";

import settings from "settings";
import { MathUtils, Vector3 } from "three";
import { useDrag } from "hooks/useDrag";
import { usePinch } from "hooks/usePinch";
import { useKey } from "hooks/useKey";
const { squareSize } = settings;

export default function CameraControl() {
  const [windowWidth, windowHeight] = useWindowSize();
  const { width, height } = useGardenStore((state) => state.settings);

  const isSmallScreen = windowWidth < breakPoints.mediumUp;
  const cameraControlSettings = isSmallScreen
    ? settings.cameraControlMobile
    : settings.cameraControl;

  const view: UIViews = useStateStore((state) => state.view);
  const cameraRef = useRef<THREE.PerspectiveCamera>();
  const domElement = useThree((state) => state.gl.domElement);
  const sphericalCamera = useRef<SmoothSphericalCamera>(null);

  let cameraSpeed = 0.01;

  // Create Camera control object
  useEffect(() => {
    if (!cameraRef.current) return;
    sphericalCamera.current = new SmoothSphericalCamera(cameraRef.current);
  }, [cameraRef]);

  // update settings
  useEffect(() => {
    if (!sphericalCamera.current) return;
    sphericalCamera.current.posMin = cameraControlSettings.clampSMin;
    sphericalCamera.current.posMax = cameraControlSettings.clampSMax;
    sphericalCamera.current.smoothing = cameraControlSettings.dampening;
  }, [cameraControlSettings]);

  // update target
  useEffect(() => {
    if (!sphericalCamera.current) return;
    sphericalCamera.current.targetDestination.x = (width / 2) * squareSize;
    sphericalCamera.current.targetDestination.z = -(height / 2) * squareSize;
  }, [view, width, height, squareSize]);

  // update film offset
  useEffect(() => {
    if (!sphericalCamera.current) return;
    sphericalCamera.current.filmOffsetDestination =
      isSmallScreen || gardenViews.includes(view) ? 0 : 6.5;
  }, [isSmallScreen, view]);

  // update position
  useEffect(() => {
    if (!sphericalCamera.current) return;
    sphericalCamera.current.destination.copy(
      cameraControlSettings.defaultSCamera[view]
    );
    if (view === UIViews.EditGarden) {
      // fit the plot in camera view
      const aspect = isSmallScreen
        ? sphericalCamera.current.camera.aspect
        : (windowWidth - 370) / windowHeight;
      sphericalCamera.current.fitRect(
        aspect,
        width * squareSize,
        height * squareSize
      );
    }
  }, [view, isSmallScreen, windowWidth, windowHeight, width, height]);

  // Updating the camera movement
  useFrame((_, deltaTime) => {
    if (!sphericalCamera.current) return;
    sphericalCamera.current.updateCamera(deltaTime);
  });

  // to prevent body scroll and other default behaviour
  useEffect(() => {
    const handler = (e) => e.preventDefault();
    document.addEventListener("gesturestart", handler);
    document.addEventListener("gesturechange", handler);
    domElement.addEventListener("contextmenu", handler);
    domElement.addEventListener("wheel", handler, {
      passive: false,
    });
    // domElement.addEventListener("wheel", handler);
    return () => {
      document.removeEventListener("gesturestart", handler);
      document.removeEventListener("gesturechange", handler);
      domElement.removeEventListener("contextmenu", handler);
      domElement.removeEventListener("wheel", handler);
    };
  }, []);

    useKey(domElement, [view], {
      onKeyUp: ({ key, code }) => {
        if (view === UIViews.ViewGarden3d) {
          cameraSpeed = 0.01;
        }
      },

      onKeyDown: ({ key, code }) => {
       
        if (view === UIViews.ViewGarden3d) {

          cameraSpeed += 0.01;
          if(cameraSpeed > 0.2) cameraSpeed = 0.2;

          const camDirForward = new Vector3();
          sphericalCamera.current.camera.getWorldDirection(camDirForward);
          camDirForward.y = 0;
          const camDirLeft = camDirForward
            .clone()
            .applyAxisAngle(new Vector3(0, 1, 0), Math.PI / 2);

          switch (code) {
            case "ArrowUp":
              sphericalCamera.current.targetDestination.add(
                camDirForward.normalize().multiplyScalar(cameraSpeed)
              );
              break;
            case "ArrowDown":
              sphericalCamera.current.targetDestination.add(
                camDirForward.normalize().multiplyScalar(-cameraSpeed)
              );
              break;
            case "ArrowLeft":
              sphericalCamera.current.targetDestination.add(
                camDirLeft.normalize().multiplyScalar(cameraSpeed)
              );
              break;
            case "ArrowRight":
              sphericalCamera.current.targetDestination.add(
                camDirLeft.normalize().multiplyScalar(-cameraSpeed)
              );
              break;
          }
        }
        


      },
    });

    useDrag(domElement, [view], {
    onDrag: ({ deltaX, deltaY, metaKey }) => {

     
      switch (view) {
        case UIViews.EditGarden:
          sphericalCamera.current.targetDestination.x += -deltaX * 0.01;
          sphericalCamera.current.targetDestination.z += -deltaY * 0.01;
          break;
        case UIViews.ViewGarden3d:
          if (metaKey) {
            //pan
            const camDirForward = new Vector3();
            sphericalCamera.current.camera.getWorldDirection(camDirForward);
            camDirForward.y = 0;
            const camDirLeft = camDirForward
              .clone()
              .applyAxisAngle(new Vector3(0, 1, 0), Math.PI / 2);

            sphericalCamera.current.targetDestination
              .add(camDirForward.normalize().multiplyScalar(deltaY * 0.01))
              .add(camDirLeft.normalize().multiplyScalar(deltaX * 0.01));
          } else {
            sphericalCamera.current.destination.theta += -deltaX * 0.01; // cameraControlSettings.deltaSAmp.theta;
            sphericalCamera.current.destination.phi += -deltaY * 0.01; // cameraControlSettings.deltaSAmp.phi;
          }
          break;
        case UIViews.ViewGardenDiagram:
        case UIViews.ViewGardenTop:
          sphericalCamera.current.targetDestination.x += -deltaX * 0.01;
          sphericalCamera.current.targetDestination.z += -deltaY * 0.01;
          break;
      }
    },
  });

  usePinch(domElement, [view], {
    onPinch(e) {
      switch (view) {
        case UIViews.EditGarden:
          sphericalCamera.current.destination.radius += -e.deltaDistance * 0.1;
          break;
        case UIViews.ViewGarden3d:
        case UIViews.ViewGardenDiagram:
        case UIViews.ViewGardenTop:
          sphericalCamera.current.destination.radius += -e.deltaDistance * 0.1; //cameraControlSettings.deltaSAmp.radius;
          break;
      }
    },
  });

  // const onWheel = useCallback(
  //   ({ delta }) => {
  //     switch (view) {
  //       case UIViews.EditGarden:
  //         sphericalCamera.current.destination.radius += deltaY * 0.05;
  //         const zoomRatio =
  //           (sphericalCamera.current.position.radius -
  //             cameraControlSettings.clampSMin.radius) /
  //           (cameraControlSettings.clampSMax.radius -
  //             cameraControlSettings.clampSMin.radius);
  //         sphericalCamera.current.destination.phi = MathUtils.lerp(
  //           MathUtils.degToRad(0),
  //           MathUtils.degToRad(60),
  //           zoomRatio
  //         );
  //         break;
  //       case UIViews.ViewGarden3d:
  //       case UIViews.ViewGardenDiagram:
  //       case UIViews.ViewGardenTop:
  //         sphericalCamera.current.destination.radius +=
  //           deltaY * cameraControlSettings.deltaSAmp.radius;
  //         break;
  //     }
  //   },
  //   [view]
  // );

  // useGesture(
  //   { onDrag, onWheel },
  //   { target: domElement, eventOptions: { passive: false }, scaleBounds: {min: 0, max: 100} }
  // );

  return <PerspectiveCamera makeDefault ref={cameraRef} />;
}
