import { MathUtils, Spherical } from "three";

export const sphericalAdd = (s1: Spherical, s2: Spherical): Spherical => {
  return new Spherical(s1.radius + s2.radius, s1.phi + s2.phi, s1.theta + s2.theta);
}

export const sphericalMultiplyScalar = (s: Spherical, scalar: number): Spherical => {
  return new Spherical(s.radius * scalar, s.phi * scalar, s.theta * scalar);
};

export const sphericalLerp = (s1: Spherical, s2: Spherical, t: number): Spherical => {
  const sOut = new Spherical();
  sOut.radius = MathUtils.lerp(s1.radius, s2.radius, t);
  sOut.theta = MathUtils.lerp(s1.theta, s2.theta, t);
  sOut.phi = MathUtils.lerp(s1.phi, s2.phi, t);
  return sOut;
}

export const sphericalClamp = (v: Spherical, sMin: Spherical, sMax: Spherical): Spherical => {
  const sOut = new Spherical();
  sOut.radius = MathUtils.clamp(v.radius, sMin.radius, sMax.radius);
  sOut.theta = MathUtils.clamp(v.theta, sMin.theta, sMax.theta);
  sOut.phi = MathUtils.clamp(v.phi, sMin.phi, sMax.phi);
  return sOut;
}