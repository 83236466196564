import { Suspense, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { ResizeObserver } from "@juggle/resize-observer";
import { ErrorBoundary } from "react-error-boundary";
import { Stats } from "@react-three/drei";
import { NoToneMapping } from "three";
import { editViews, gardenViews, UIViews, useStateStore } from "stores/stateStore";
// import { Button } from "components/UI/Button";

import CameraControl from "../CameraControl/CameraControl";

import { useGardenStore } from "stores/gardenStore";
import { EditGarden } from "../EditGarden/EditGarden";
import ViewGarden from "../ViewGarden/ViewGarden";

import settings from "settings";
import { ErrorModal } from "components/ErrorModal/ErrorModal";
import { useRouter } from "next/router";
const { squareSize } = settings;

export default function GardenCanvas() {
  const view: UIViews = useStateStore((state) => state.view);
  const pollinatorView = useStateStore((state) => state.pollinatorView);
  const { pathname } = useRouter();

  return (
    <div
      className={`CanvasWrapper ${pollinatorView ? "pollinatorView" : ""} ${
        pathname === "/" && "hide"
      }`}
    >
      <ErrorBoundary FallbackComponent={ErrorModal}>
        <Canvas
          resize={{ polyfill: ResizeObserver }}
          style={{ touchAction: "none" }}
          gl={{
            preserveDrawingBuffer: true,
            alpha: false,
            stencil: false,
            // toneMapping: NoToneMapping,
            // antialias: false,
            powerPreference: "high-performance",
          }}
          onCreated={({ gl }) => {
            // gl.gammaInput = true;
            gl.toneMapping = NoToneMapping;
          }}
          dpr={1}
        >
          <color attach="background" args={["white"]} />
          {/* <Stats className="stats" /> */}
          {/* <fog attach="fog" args={["white", 0, 40]} /> */}
          {/* <axesHelper /> */}
          {/* <ambientLight args={[0x404040, 0.5]}/> */}
          {/* <pointLight position={[0,10,0]} args={[0xffffff, 1, 0, 2]}/> */}
          <CameraControl />
          <gridHelper
            args={[150, 150 * (1 / squareSize), "#ddd", "#ddd"]}
            position-y={-0.01}
          />
          <Suspense fallback={null}>
            {editViews.includes(view) && <EditGarden />}
            {gardenViews.includes(view) && <ViewGarden />}
          </Suspense>
        </Canvas>
      </ErrorBoundary>
      {/* <Loader /> */}
      <style jsx>{`
        .CanvasWrapper {
          position: fixed;
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          // z-index: -1;
        }
        .CanvasWrapper.hide {
          visibility: hidden;
        }

        .CanvasWrapper :global(canvas) {
          touch-action: none;
        }

        .CanvasWrapper.pollinatorView {
          //background-color: #a52020;
        }
        .CanvasWrapper.pollinatorView > :global(div) {
          // mix-blend-mode: exclusion;
        }
        :global(.stats) {
          left: auto !important;
          right: 0 !important;
        }
      `}</style>
    </div>
  );
}
