// import { nodeIdToCoords } from "lib/coordinatesHelper";
import { useLayoutEffect, useRef } from "react";
import { BufferGeometry, InstancedMesh, Object3D, NearestFilter } from "three";
import { a, useSpring } from "@react-spring/three";
import { useTexture } from "@react-three/drei";
import { GardenSettings, useGardenStore } from "stores/gardenStore";
import { soilTextures, soilPhColors, hslToString } from "styles/styles";

import settings from "settings";
const { squareSize, maxPixels } = settings;

const helperObject = new Object3D();

type EditGardenGridProps = {
  gardenSettings: GardenSettings
};

const EditGardenGrid = ({ gardenSettings }: EditGardenGridProps) => {
  const { width, height, remove, soil_requirements, soil_ph, light_requirements, exposure } = gardenSettings;
  const getCoordForNodeId = useGardenStore((state) => state.getCoordForNodeId);

  const ref = useRef<InstancedMesh>(null);
  const squarePlaneRef = useRef<BufferGeometry>(null);

  const textures = useTexture(Object.values(soilTextures));
  textures.forEach((texture)=> {
    texture.anisotropy = 4;
  });

  const phColors = Object.values(soilPhColors);

  const colorTo = {...phColors[soil_ph]};
  colorTo.l += -(light_requirements - 1) * 8; 
  colorTo.h += (exposure - 2) * 20; 

  const { color } = useSpring({
    color: hslToString(colorTo),
    from: { color: hslToString(soilPhColors.none) },
  });

  useLayoutEffect(() => {
    squarePlaneRef.current.rotateX(-Math.PI / 2);
  }, []);

  useLayoutEffect(() => {
    for (let i = 0; i < maxPixels; i++) {
      const scale = i >= width * height || remove.includes(i) ? 0 : 1;
      helperObject.scale.set(scale, scale, scale);
      const {x, y} = getCoordForNodeId(i);
      helperObject.position.setX((x + 0.5) * squareSize);
      helperObject.position.setZ(-(y + 0.5) * squareSize);
      helperObject.updateMatrix();
      ref.current.setMatrixAt(i, helperObject.matrix);
    }
    ref.current.instanceMatrix.needsUpdate = true;
  }, [width, height, remove]);

  return (
    <instancedMesh
      ref={ref}
      args={[null, null, maxPixels]}
      position-z={0}
    >
      <planeBufferGeometry
        ref={squarePlaneRef}
        args={[squareSize, squareSize, 1]}
      />
      <a.meshBasicMaterial
        map={textures[soil_requirements || 0]}
        color={color}
      />
    </instancedMesh>
  );
};

export default EditGardenGrid;
