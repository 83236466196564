import { useState, useEffect, useRef } from "react";

interface KeyEvent extends KeyboardEvent {
 
}

type KeyCallBacks = {
    onKeyDown?: (e: KeyEvent) => void;
    onKeyUp?: (e: KeyEvent) => void;
};


export const useKey = (element, deps = [], {onKeyDown, onKeyUp}: KeyCallBacks) => {


  const handleKeyDown = (e: KeyEvent) => {
    const name = e.key;
    var code = e.code;
    console.log(name)
    if(onKeyDown)  onKeyDown(e);
  };

  const handleKeyUp = (e: KeyEvent) => {
    var name = e.key;
    var code = e.code;
    if(onKeyUp) onKeyUp(e);
  };

  

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    console.log("gogogo")
   // element.addEventListener("pointermove", handlePointerMove);
    return () => {
        document.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("keyup", handleKeyUp);
      //element.removeEventListener("pointermove", handlePointerMove);
    };
  }, [...deps]);

  return {};
};
