import { Line, Html } from "@react-three/drei"
import { Vector3 } from "three";

const Dimension = ({size, labelStyle, ...props }) => {
  const tickSize = 0.2;
  
  const points: [number, number, number][] = [
    [0,0,-tickSize],
    [0,0,0],
    [size,0,0],
    [size,0,-tickSize]
  ];
  return (
    <group {...props}>
      <Line points={points} lineWidth={0.3} />
      <Html center position={[size / 2, 0, 0]}>
        <div style={{position: 'relative', ...labelStyle}}>{size}m</div>
      </Html>
    </group>
  );
}

export default Dimension;